<template>
	<div class="gift-code">
		<van-nav-bar
			title="禮品碼"
			left-arrow
			class="qjc-agent-navbar"
			@click-left="$router.back()"
		></van-nav-bar>
		
		<!-- 需知 -->
		<div class="need-know qjc-texta-l qjc-fts-30">
			<h4>禮品碼需知：</h4>
			<p>1.生成禮品碼時將立刻扣除一次您的預購次數，並不計入您的直銷業績，也不進行直銷返利</p>
			<p>2.客戶在購買“疫境求真”體檢套餐時，填寫禮品碼即可抵扣全部費用</p>
			<p>3.每個禮品碼僅可使用一次</p>
		</div>
		
		<div class="title qjc-c-primary">
			<span class="gift qjc-block qjc-fts-34">禮品碼</span>
			
			<van-button @click="needKnow = true" class="creat qjc-fts-30">生成禮品碼</van-button>
			
		</div>
		
		<div id="code" :class="{ overlay: !giftCode }">
			<vue-qr
				:logoSrc="require('@/assets/images/agent_miyinlogo.png')"
				:text="codeUrl"
				:size="500"
				:margin="0"
				:logoScale=".25"
				:correctLevel="3"
			></vue-qr>
		</div>
		
		<div class="code-text qjc-fts-30">
			禮品碼：<span id="codeText">{{ giftCode?giftCode:'————' }}</span><span @click="copy('.c-code', giftCode?true:false)" data-clipboard-target="#codeText" class="c-code qjc-c-primary">複製</span>
		</div>
		
		<!-- 列表 -->
		<van-list
		  v-model="loading"
		  :finished="finished"
		  :finished-text="list.length > 0?'沒有更多了':''"
		  @load="onLoad"
		  class="list qjc-texta-l"
		>
			<h4 class="qjc-fts-30">禮品碼</h4>
			<template v-if="list.length > 0">
				<div v-for="(item, index) in list" :key="index" class="item qjc-fts-30">
					<div>
						<span :class="'code-text'+index" class="text">{{ item.code }}</span>
						<span @click="copy('.copy-list'+index, item.used == 0?true:false)" :data-clipboard-target="'.code-text'+index" :class="[ item.used == 1?'gray':'qjc-c-primary', 'copy-list'+index ]">複製</span>
					</div>
					<dl class="qjc-texta-r">
						<dt :class="{ gray: item.used == 0?false:true }">{{ item.used == 0?'未使用':(item.used == 1?'已使用':'--') }}</dt>
						<dd class="gray qjc-fts-22">{{ item.created_at }}</dd>
					</dl>
				</div>
			</template>
			
			<p v-else class="no-list qjc-texta-c qjc-fts-26 gray">暫時没有已生成禮品碼</p>
		</van-list>
		
		<!-- 需知弹窗 -->
		<van-popup v-model="needKnow" class="need-know qjc-texta-l qjc-fts-30">
			<h4>禮品碼需知：</h4>
			<p>1.生成禮品碼時將立刻扣除一次您的預購次數，並不計入您的直銷業績，也不進行直銷返利</p>
			<p>2.客戶在購買“疫境求真”體檢套餐時，填寫禮品碼即可抵扣全部費用</p>
			<p>3.每個禮品碼僅可使用一次</p>
			<div class="btns qjc-fts-32">
				<van-button @click="needKnow = false" class="cancel qjc-c-primary">
					<img class="qjc-vertical-m" src="../../../assets/images/agent_arrow_primary.png" alt="">
					<span class="qjc-vertical-m">取消</span>
				</van-button>
				<van-button @click="creatCode" class="confirm qjc-c-fff qjc-bg-primary">
					<span class="qjc-vertical-m">確定</span>
					<img class="qjc-vertical-m" src="../../../assets/images/agent_login_arrow.png" alt="">
				</van-button>
			</div>
		</van-popup>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Popup, Button, List, Toast } from 'vant'
	Vue.use(NavBar)
	   .use(Popup)
	   .use(Button)
	   .use(List)
	   .use(Toast);
	
	import vueQr from 'vue-qr'
	import Clipboard from 'clipboard';
	
	import { genGiftCode, giftCodeList } from '@/js/axios'
	
	export default{
		name: "agentInvitationGiftCode",
		data() {
			return {
				needKnow: false,// 需知
				
				codeUrl: 'https://m.medinbd.com/#/',//二维码，默认香港体检手机端首页
				
				giftCode: '',// 礼品码
				
				list: [],
				loading: false,
				finished: false,
				
				pager: {
					page: 1,// 默认第一页
					pagesize: 5,// 每页5条
				},
			}
		},
		components: {
			vueQr
		},
		watch: {
			giftCode(code) {
				this.codeUrl = location.origin + '/' + this.$router.resolve({
					path: '/payment/0',
					query: {
						giftCode: code
					}
				}).href;
			}
		},
		methods: {
			// 生成礼品码
			creatCode() {
				genGiftCode().then(res => {
					// console.log(res);
					if(res.code == 200){
						this.giftCode = res.data.giftCode;
						this.needKnow = false;
						
						// 重置列表
						this.list = [];
						this.pager.page = 1;
						this.onLoad();
						
					}else{
						Toast.fail(res.msg);
					}
				});
			},
			
			// 复制 参数：className:类名，用来绑定复制事件。  canUse:是否能复制(无礼品码和已使用不可复制)
			copy(className, canUse) {
			  if(canUse){
				  var clipboard = new Clipboard(className);
					clipboard.on('success', e => {  
						// 同步二维码及礼品码
						this.giftCode = e.text;
						
						Toast.success("複製成功");
						// 释放内存  
						clipboard.destroy()  
					})  
					clipboard.on('error', e => {  
					  // 不支持复制  
					  Toast.fail('該瀏覽器不支持自動複製')  
					  // 释放内存  
					  clipboard.destroy()  
					})  
			  }
			},
			
			onLoad() {// 列表更新
				giftCodeList(this.pager).then(res => {
					// console.log(res)
					// 加载状态结束
					this.loading = false;
					
					if(res.code == 200){
						this.list = this.list.concat(res.data.data);
					
						// 数据全部加载完成
						if (this.pager.page >= res.data.pager.pagecount) {
						  this.finished = true;
						}
					}else{
						this.finished = true;
						Toast.fail(res.msg);
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.gift-code{
		color: #3B4549;
		
		&>.need-know{
			padding: 0.24rem;
		}
		
		.need-know{
			padding: 0.36rem 0.4rem;
			border-radius: 0.08rem;
			
			&.van-popup{
				width: 5.44rem;
			}
			h4{
				line-height: 0.42rem;
				font-weight: 500;
				margin-bottom: 0.16rem;
			}
			p{
				line-height: 0.46rem;
			}
			.btns{
				display: flex;
				justify-content: space-between;
				margin-top: 0.48rem;
				
				.van-button{
					display: flex;
					align-items: center;
					justify-content: center;
					width: 2.2rem;
					height: 0.8rem;
					line-height: 0.8rem;
					font-size: 0.32rem;
					border-radius: 0.08rem;
					border-color: #6883FB;
					
					img{
						width: 0.59rem;
						height: auto;
						margin-top: -0.1rem;
					}
					&.cancel{
						img{
							margin-right: 0.11rem;
						}
					}
					&.confirm{
						img{
							margin-left: 0.11rem;
						}
					}
				}
			}
			
		}
	
		.title{
			display: flex;
			width: 5.58rem;
			justify-content: space-between;
			border-bottom: 0.01rem solid #E1E3EB;
			padding: 0 0.22rem;
			margin: 0 auto 0.44rem;
			
			.gift{
				position: relative;
				height: 0.76rem;
				line-height: 0.48rem;
				
				&::after{
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					height: 0.06rem;
					background-color: #6883FB;
					border-radius: 0.06rem;
				}
			}
			.creat{
				padding: 0 0.32rem;
				height: 0.62rem;
				line-height: 1;
				color: #6883FB;
				font-weight: 500;
				border: 0.01rem solid #6883FB;
				border-radius: 0.31rem;
			}
			
		}
		
		#code{
			position: relative;
			width: 5.14rem;
			height: 5.14rem;
			margin: 0 auto 0.28rem;
			
			&.overlay{
				filter: blur(0.1rem);
				
				&::after{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba(255, 255, 255, .7);
				}
			}
			img{
				width: 100%;
				height: 100%;
			}
		}
		
		.code-text{
			width: 3.88rem;
			line-height: 0.56rem;
			border-radius: 0.28rem;
			background-color: #DADFF5;
			margin: 0 auto 0.72rem;
			
			span.qjc-c-primary{
				margin-left: 0.24rem;
			}
			
		}
		
		.list{
			border-top: 0.12rem solid #F5F6FA;
			padding: 0.32rem 0.24rem;
			
			h4{
				line-height: 0.3rem;
				margin-bottom: 0.18rem;
			}
			
			.item{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 0.94rem;
				border-bottom: 0.01rem solid #E1E3EB;
				
				.text{
					display: inline-block;
					min-width: 1.77rem;
				}
				dl{
					dt{
						line-height: 0.3rem;
					}
					dd{
						line-height: 0.22rem;
						margin-top: 0.12rem;
					}
				}
			}
			.no-list{
				margin-top: 0.56rem;
			}
			.gray{
				color: #8C9699;
			}
			
		}
		
	}
</style>
